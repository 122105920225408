import Header from "../components/Header/Header";
import Catalog from "../components/Catalog/Catalog";
import Footer from "../components/Footer/Footer";
import {Theme} from "@radix-ui/themes";
import Favorites from "../components/Catalog/Favorites/Favorites";

export default function FavoritesPage(){
    return(
        <Theme>
            <Header/>
            <Favorites/>
            <Footer/>
        </Theme>
    )
}