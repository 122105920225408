import {Box, Container, Flex, Text} from "@radix-ui/themes";
import './Footer.scss'
import siteLogo from '../../static/img/sitelogo.svg'
import {Link} from "react-router-dom";


export default function Footer() {
    return (
        <Box style={{borderTop: '1px solid rgba(0,0,0,0.1)'}} py={'5'} mt={'8'} width={'100%'}>
            <Container>
                <Flex direction={{
                    initial: 'column',
                    sm: 'row'
                }} align={"center"} gap={{
                    initial: '4',
                    sm: '8'
                }} justify={"center"}>
                    <Flex align={{
                        initial: 'center',
                        sm: 'start'
                    }} direction={"column"}>
                        <Text size={{
                            initial: '4',
                            sm: '6'
                        }}>Телеграм</Text>
                        <a className={'footer__link'}
                           href='https://t.me/basetwentyfour'>
                            <Text  mt={{
                                initial: '0',
                                sm: '1'
                            }} size={{
                                initial: '1',
                                sm: '2'
                            }}>@basetwentyfour</Text>
                        </a>
                    </Flex>
                    <Link to={'/'}>
                        <Box display={{
                            initial: 'none',
                            sm: 'block'
                        }}>
                            <img alt={'logo'} src={siteLogo}/>
                        </Box>
                    </Link>

                    <Flex align={{
                        initial: 'center',
                        sm: 'start'
                    }} direction={"column"}>
                    <Text size={{
                        initial: '4',
                        sm: '6'
                    }}>Инстаграм</Text>
                        <a className={'footer__link'}
                           href='https://www.instagram.com/basetwentyfour?igsh=N2Fmb3ZmOTFmbWV1'>
                            <Text mt={{
                                initial: '0',
                                sm: '1'
                            }} size={{
                                initial: '1',
                                sm: '2'
                            }}>@basetwentyfour</Text>
                        </a>
                    </Flex>
                </Flex>
            </Container>
        </Box>
    )
}