import {Box, Button, Container, Flex, Heading, Skeleton, Text} from "@radix-ui/themes";
import React, {useEffect, useState} from 'react'
import {StarFilledIcon} from "@radix-ui/react-icons";
import {Link, useParams} from "react-router-dom";
import ImageGallery from "react-image-gallery";
import './Item.scss'
import {FavoritesAPI} from "../../lib/helpers/FavoritesAPI";

export default function Item() {
    let {itemId} = useParams();
    const [images, setImages] = useState([])
    const [isFav, setIsFav] = useState(false)
    const [loadedItem, setLoadedItem] = useState({})
    let changeFav = () =>{
        if(isFav){
            FavoritesAPI.removeFromFavorites(itemId)
            setIsFav(false)
        } else {
            FavoritesAPI.addToFavorites(itemId)
            setIsFav(true)
        }
    }
    let fetchData = async () => {
        try {
            let data = await fetch('https://api.basetwentyfour.com:6500/products/' + itemId)

            let parsedData = await data.json()
            if(parsedData){
                setLoadedItem(parsedData)
                let newImages = [];
                for(let photo of parsedData.photos){
                    newImages.push({
                        original: photo,
                        thumbnail: photo
                    })
                }
                setImages(newImages)
                setLoadedItem(parsedData)
            }
        } catch (e){
            console.log(e)
        }
    }
    useEffect( () => {
        let favList = FavoritesAPI.getFavorites()
        if(favList?.length > 0){
            if(favList.indexOf(itemId) > -1){
                setIsFav(true)
            }
        }
        fetchData()
    }, []);
    return (
        <Container size={{
            lg: '4',
            md: '3',
            sm: '2',
            xs: '1',
        }} px={{
            initial: '4',
            sm: '1'
        }}>
            <Flex direction={{
                initial: 'column',
                xs: 'row'
            }} gap={"6"} mt={"8"} >
                <Box width={{
                    initial: '100%',
                    xs: '50%'
                }}>
                {
                    images.length > 0 ?
                        <ImageGallery
                            showPlayButton={false}
                            showFullscreenButton={false}
                            items={images} />
                    : ''
                }
                </Box>
                <Flex direction={"column"} width={{
                    initial: '100%',
                    xs: '50%'
                }}>
                    <Text size={"2"} weight={'medium'}>Chrome hearts</Text>
                    <Flex wrap={{
                        initial: 'wrap',
                        md: 'nowrap'
                    }} gapX={{
                        initial: '8',
                        md: '8',
                        lg: '8'
                    }} gapY={'2'} mt={"4"}>
                        <Flex width={{
                            initial: '120px',
                            md: 'auto'
                        }} direction={"column"}>
                            <Text size={"1"} weight={'light'}>Пол</Text>
                            <Text size={"3"} weight={'regular'}>{loadedItem.gender === 'male' ? 'Мужской' : 'Женский'}</Text>
                        </Flex>
                        <Flex width={{
                            initial: '120px',
                            md: 'auto'
                        }} direction={"column"}>
                            <Text size={"1"} weight={'light'}>Размер</Text>
                            <Text size={"3"} weight={'regular'}>{loadedItem.size}</Text>
                        </Flex>
                        <Flex width={{
                            initial: '120px',
                            md: 'auto'
                        }} direction={"column"}>
                            <Text size={"1"} weight={'light'}>Вес</Text>
                            <Text size={"3"} weight={'regular'}>{loadedItem.weight}</Text>
                        </Flex>
                        <Flex width={{
                            initial: '120px',
                            md: 'auto'
                        }} direction={"column"}>
                            <Text size={"1"} weight={'light'}>Цена</Text>
                            <Text  size={"3"} weight={'bold'}>По запросу</Text>
                        </Flex>
                    </Flex>


                    <Link style={{width: '100%'}} to={'https://t.me/basetwentyfour'}>
                        <Button  size={{
                            initial: '2',
                            sm: '3',
                            md: '4'
                        }} mt={'4'} style={{
                            width: '100%',
                            backgroundColor: "black",
                            cursor: "pointer",
                            borderRadius: 0
                        }}>
                            Связаться с нами
                        </Button>
                    </Link>

                    <Button onClick={changeFav} size={{
                        initial: '2',
                        sm: '3',
                        md: '4'
                    }} mt={'1'} style={{
                        backgroundColor: "white",
                        cursor: "pointer",
                        borderRadius: 0,
                        color: "black",
                        border: '1px solid black'
                    }}>
                        {!isFav ? 'Добавить в избранное': 'Удалить из избранного'}
                    </Button>

                </Flex>
            </Flex>
        </Container>
    )

}


