import siteLogo from "../../static/img/sitelogo.svg";
import "./Header.scss"
import NavBar from "./components/NavBar/NavBar";
import {Box, Container, Flex} from "@radix-ui/themes";
import {Link} from "react-router-dom";

export default function Header() {
    return (
        <Box pt={{
            initial: '4',
            xs: '6'
        }} pb={"5"} style={{
            borderBottom: '1px solid gray',
        }}>
            <Container size={{
                lg: '4',
                md: '3',
                sm: '2',
                xs: '1'
            }}  px={{
                initial: '4',
                sm: '1'
            }}>
                <Flex justify={"center"}>
                    <Link to={'/'}>
                        <Box width={{
                            initial: '210px',
                            xs: '260px'
                        }}>
                            <img style={{
                                width: '100%'
                            }} alt={'siteLogo'} src={siteLogo}/>
                        </Box>
                    </Link>
                </Flex>
                <NavBar/>
            </Container>
        </Box>
    )
}