import {Theme} from "@radix-ui/themes";
import Header from "../components/Header/Header";
import Item from "../components/Item/Item";
import Footer from "../components/Footer/Footer";

export default function ItemPage(){
    return (
        <Theme>
            <Header/>
            <Item/>
            <Footer/>
        </Theme>
    )
}