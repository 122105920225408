import {Box, Heading, Skeleton, Text} from "@radix-ui/themes";
import React, {useEffect, useState} from 'react'
import {StarFilledIcon} from "@radix-ui/react-icons";
import {Link} from "react-router-dom";
import {FavoritesAPI} from "../../../../lib/helpers/FavoritesAPI";
import './ItemCard.scss'

export default function ItemCard(props) {
    const [isFavorite, setIsFavorite] = useState(false)
    const [smoothLoad, setSmoothLoad] = useState(true)
    useEffect(() => {
        setIsFavorite(props.isFavorite);
    }, []);
    let addToFav = (e, link) => {
        let id = link.replace('/products/', '');
        e.preventDefault()
        setIsFavorite(!isFavorite);
        if(!props.isFavorite){
            FavoritesAPI.addToFavorites(id)
            return
        }
        FavoritesAPI.removeFromFavorites(id)
    }
    useEffect(() => {
        setTimeout(() => {
            let elsSkelet = document.getElementsByClassName('itemCard__skeleton')
            for(let el of elsSkelet){
                el.classList.add('hide')
            }
            setSmoothLoad(false)
            setTimeout(() => {
                let els = document.getElementsByClassName('itemCard__card')
                console.log(els)
                for(let el of els){
                    el.classList.add('show')
                }
            }, 150)

        }, 1000)
    }, []);

    const [isImageLoaded, setIsImageLoaded] = useState(false)
    if(props.empty === true || smoothLoad){
        return (
            <Box className={'itemCard__skeleton'} style={{
                border: 0,
                padding: 0,
                position: "relative",
                transitionDuration: '300ms'
            }}>
                <Skeleton width={{
                    lg: "192px",
                    md: '160px',
                    sm: '145px',
                    initial: '100%'
                }} height={{
                    lg: "192px",
                    md: '150px',
                    sm: '160px',
                    initial: '150px'
                }}/>

                <Skeleton>
                    <Heading mt={'2'} size={"2"}>HEARTS</Heading>
                </Skeleton>
                <Skeleton width={'150px'}>
                    <Text size={"2"}>Размер: М</Text>
                </Skeleton>
                <Skeleton  width={{
                    md: '150px',
                    sm: '145px',
                    initial: '100%'
                }}>
                    <Heading mt={"2"} weight={"medium"} size={"3"}>Цена: по запросу</Heading>
                </Skeleton>
            </Box>
        )
    }
    let item = props.item;
    return (
        <Link style={{ textDecoration: 'none', color: "inherit" }} to={item.goodLink}>
            <Box className={'itemCard__card'} style={{
                border: 0,
                padding: 0,
                position: "relative"
            }}>
                <Box style={{
                    position: "absolute",
                    left: 10,
                    top: 10
                }} onClick={e => addToFav(e, item.goodLink)}>
                    <StarFilledIcon width={"20"} height={"20"} color={!isFavorite ? "lightgray": 'rgba(255, 179, 66, 1)'}/>
                </Box>
                {
                    !isImageLoaded ? <Skeleton width={{
                        lg: "192px",
                        md: '160px',
                        sm: '145px',
                        initial: '100%'
                    }} height={{
                        lg: "192px",
                        md: '150px',
                        sm: '160px',
                        initial: '150px'
                    }} /> : ''
                }
                <Box width={{
                    lg: "192px",
                    md: '160px',
                    sm: '145px',
                    initial: '100%'
                }} height={{
                    lg: "192px",
                    md: '150px',
                    sm: '160px',
                    initial: '100%'
                }}>
                    <img onLoad={() => setIsImageLoaded(true)}
                         style={isImageLoaded ? {width: "100%"} : {display: 'none'}} alt="test" src={item.imageLink}/>

                </Box>

                <Heading mt={'2'} size={"2"}>{item.brand}</Heading>
                <Text size={"2"}>{item.size}</Text>
                <Heading mt={"2"} weight={"medium"} size={"3"}>Цена: по запросу</Heading>
            </Box>
        </Link>
    )

}


