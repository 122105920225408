import {Box, Container, Flex, Heading} from "@radix-ui/themes";
import DropdownFilters from "../DropdownFilters/DropdownFilters";
import CatalogGrid from "../CatalogGrid/CatalogGrid";
import {useEffect, useState} from "react";
import {FavoritesAPI} from "../../../lib/helpers/FavoritesAPI";
import {useLocation, useParams} from "react-router-dom";

export default function Favorites(){
    const [isNotFound, setIsNotFound] = useState(false)
    const [items, setItems] = useState([])
    const [pagesTotal, setPagesTotal] = useState(0)
    const [goodsTotal, setGoodsTotal] = useState(0)
    let { page } = useParams();
    let location = useLocation()
    let fetchData = async () => {
        let itemsIds = FavoritesAPI.getFavorites();
        let favItems = [];
        if(itemsIds?.length> 0){
            for(let itemId of itemsIds){
                try {
                    let data = await fetch('https://api.basetwentyfour.com:6500/products/' + itemId)
                    let parsedData = await data.json()
                    if(parsedData){
                        favItems.push(parsedData);
                    }
                    console.log(favItems)
                } catch (e){
                    console.log(e)
                }
            }
        }
        if(favItems.length > 0){
            setItems(favItems)
            setGoodsTotal(favItems.length)
            setPagesTotal(Math.ceil(favItems.length / 48))
        } else {
            setIsNotFound(true)
        }
    }
    useEffect( () => {
        setItems([])
        setGoodsTotal(0)
        setIsNotFound(false)
        fetchData()
    }, [location]);
    return(
        <Box>
            {!isNotFound ? <Container pt={{
                initial: '5',
                sm: '9'
            }} size={{
                lg: '4',
                md: '3',
                sm: '2',
                xs: '1'
            }} px={{
                initial: '4',
                sm: '1'
            }}>

                <Flex direction={"row"} justify={"center"} align={"center"}>
                    <Heading mb="1" weight={"bold"} size="7">Избранное</Heading>
                </Flex>
                <Flex justify={"center"} align={"end"} gap={"1"}>
                    <Heading mb="2" weight={"light"} size="3">Товаров:</Heading>
                    <Heading mb="2" weight={"medium"} size="4">{goodsTotal}</Heading>
                </Flex>
            </Container> : ''}
            {!isNotFound ?
                <CatalogGrid isFavorites={true} isNotFound={isNotFound} pagesTotal={pagesTotal} items={items} page={page}/> :
                <Container mb={'auto'} pt={{
                    initial: '8',
                    sm: '9'
                }} size={{
                    lg: '4',
                    md: '3',
                    sm: '2',
                    xs: '1'
                }} px={{
                    initial: '4',
                    sm: '1'
                }}>
                    <Flex justify={"center"}>
                        У вас нет товаров в избранном
                    </Flex>
                </Container>}

        </Box>
    )
}