import {Box, Container, Flex, Grid} from "@radix-ui/themes";
import React, {useEffect, useState} from 'react'
import CatalogFilters from "../CatalogFilters/CatalogFilters";
import ItemCard from "./ItemCard/ItemCard";
import ReactPaginate from "react-paginate";
import {ChevronLeftIcon, ChevronRightIcon} from "@radix-ui/react-icons";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import './CatalogGrid.scss'
import {FavoritesAPI} from "../../../lib/helpers/FavoritesAPI";


export default function CatalogGrid(props) {
    const [favorites, setFavorites] = useState([])
    useEffect(() => {
        console.log(document.cookie)
        let fav = FavoritesAPI.getFavorites()
        setFavorites(fav);
    }, []);
    let isFavorite = (link) => {
        let id = link.replace('/products/', '');
        if(!id || !favorites){
            return false
        }
        if(favorites.indexOf(id) > -1){
            return true
        }
        return false
    }

    let items = props.items;
    let { page } = useParams();
    let location = useLocation();
    const navigate = useNavigate();
    let partedLocation = location.pathname.split('/')
    let path = "";
    for(let part of partedLocation){
        if(part !== page && part){
            path += '/' + part
        }
    }
    let EmptyGrid = () => {
        let grid = []
        for(let i = 0; i < 48; i++){
            grid.push(<ItemCard empty={true}/>)
        }
        return grid
    }
    let handleChangePage = (e) => {
        navigate(path + '/' + (e.selected + 1))
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    return (
        <Container py={"1"} size={{
            lg: '4',
            md: '3',
            sm: '2',
            xs: '1'
        }} px={{
            initial: '4',
            sm: '1'
        }}>
            <Flex gap={"6"} justify={!props.isFavorites && !props.isHome ? "between" : 'center'}>
                {!props.isFavorites && !props.isHome ? <CatalogFilters/> : ''}
                {
                    !props.isNotFound ?
                        <Flex width={{
                            initial: '100%',
                            sm: 'auto'
                        }} direction={"column"} align={"center"}>
                            <Grid gap={"3"} width={'100%'} columns={!props.isHome ? {
                                initial: "2",
                                md: '4',
                                sm: '3'
                            } : {
                                initial: "2",
                                md: '5',
                                sm: '4'
                            }}>
                                {
                                    items?.length > 0 ? items.map((item) => {
                                        return <ItemCard
                                            isHome={props.isHome}
                                            item={item}
                                            isFavorite={isFavorite(item.goodLink)}
                                        />
                                    }) : <EmptyGrid/>
                                }
                            </Grid>{
                            props.pagesTotal ? <ReactPaginate
                                containerClassName={"pagination"}
                                pageClassName={"pagination__page"}
                                activeClassName={"pagination__activePage"}
                                nextClassName={"pagination__next"}
                                previousClassName={"pagination__prev"}
                                initialPage={page ? parseInt(page) - 1 : 0}
                                breakLabel="..."
                                onPageChange={handleChangePage}
                                nextLabel={<ChevronRightIcon/>}
                                pageRangeDisplayed={3}
                                pageCount={props.pagesTotal}
                                previousLabel={<ChevronLeftIcon/>}
                                renderOnZeroPageCount={null}
                            /> : ''
                        }
                        </Flex>
                        :<></>
                }

            </Flex>
        </Container>
    )

}


