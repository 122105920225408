import {Box, Button, ChevronDownIcon, Flex, Heading} from "@radix-ui/themes";
import * as Accordion from '@radix-ui/react-accordion';
import {AccordionContent, AccordionTrigger} from "@radix-ui/react-accordion";
import * as Checkbox from '@radix-ui/react-checkbox';
import {CheckIcon} from "@radix-ui/react-icons";
import './CatalogFilters.scss'
import React from 'react'
import classNames from 'classnames';
import {useLocation, useNavigate, useParams} from "react-router-dom";

export default function CatalogFilters() {
    let { categoryName } = useParams()
    let { page } = useParams()
    let location = useLocation()
    let navigate = useNavigate()
    const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
        <Accordion.Header className="AccordionHeader">
            <Accordion.Trigger
                className={classNames('AccordionTrigger', className)}
                {...props}
                ref={forwardedRef}
            >
                {children}
                <ChevronDownIcon className="AccordionChevron" aria-hidden />
            </Accordion.Trigger>
        </Accordion.Header>
    ));

    const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
        <Accordion.Content
            className={classNames('AccordionContent', className)}
            {...props}
            ref={forwardedRef}
        >
            <div className="AccordionContentText">{children}</div>
        </Accordion.Content>
    ));
    let subcats = [];
    if(categoryName === 'bags'){
        subcats = [{
            name: 'tout',
            title: 'Тоут',

        },{
            name: 'hand',
            title: 'С ручками',

        },{
            name: 'clutch',
            title: 'Клатчи',

        },{
            name: 'briefcase',
            title: 'Портфели',

        },{
            name: 'travelling',
            title: 'Дорожные сумки',

        },{
            name: 'shoulder',
            title: 'Через плечо',

        },{
            name: 'backpack',
            title: 'Рюкзаки',
        },]
    }
    if(categoryName === 'accessories'){
        subcats = [{
            name: 'lighters',
            title: 'Зажигалки',

        },{
            name: 'scarf',
            title: 'Шарфы и платки',

        },{
            name: 'watches',
            title: 'Часы',

        },{
            name: 'belt',
            title: 'Ремни',

        },{
            name: 'gloves',
            title: 'Перчатки',

        },{
            name: 'cardCase',
            title: 'Кардхолдеры',

        },{
            name: 'keyPurse',
            title: 'Ключницы',
        },{
            name: 'changePurse',
            title: 'Мелочницы',
        },{
            name: 'wallet',
            title: 'Кошельки',
        },{
            name: 'sunglasses',
            title: 'Солнцезащитные очки',
        },{
            name: 'otherGoods',
            title: 'Прочее',
        },]
    }
    if(categoryName === 'jewelery'){
        subcats = [{
            name: 'walletChain',
            title: 'Цепи для кошелька',

        },{
            name: 'bracelet',
            title: 'Браслеты',

        },{
            name: 'earrings',
            title: 'Серьги',

        },{
            name: 'pendant',
            title: 'Подвески',

        },{
            name: 'ring',
            title: 'Кольца',

        },{
            name: 'necklace',
            title: 'Цепочки на шею',
        }]
    }
    let handleChangeLink = (data) => {
        let fullPath = ''
        if(data.isOn){
            let splittedPath = location.pathname.split('/')
            console.log(splittedPath)
            if(splittedPath.length === 3){
                fullPath = '/' + splittedPath[1] + '/' + splittedPath[2] + '/' + data.type + '=' + data.value
            }
            if(splittedPath.length === 4){
                fullPath = '/' + splittedPath[1] + '/' + splittedPath[2]
                if(splittedPath[3].includes('gender') || splittedPath[3].includes('sub')){
                    fullPath += '/' + splittedPath[3] + '&' + data.type + '=' + data.value
                } else {
                    fullPath += '/' + data.type + '=' + data.value + '/1'
                }

            }
            if(splittedPath.length === 5){
                fullPath = '/' + splittedPath[1] + '/' + splittedPath[2] + '/' + splittedPath[3] + '&' + data.type + '=' + data.value + '/' + 1
            }
        } else{
            let splittedPath = location.pathname.split('/')
            if(splittedPath.length === 3){
                fullPath += '/' + data.type + '=' + data.value + '/1'
            }
            if(splittedPath.length === 4 || splittedPath.length === 5){
                fullPath = '/' + splittedPath[1] + '/' + splittedPath[2]
                if(splittedPath[3].includes('gender') || splittedPath[3].includes('sub')){
                    let splittedDataPath = splittedPath[3].split('&');
                    if(splittedDataPath.length > 1){
                        let newPath = '/';
                        let isFirst = true
                        for(let i = 0; i < splittedDataPath.length; i++){
                            if(data.type + '=' + data.value !== splittedDataPath[i]){
                                if(!isFirst){
                                    newPath += '&'
                                } else {
                                    isFirst = false
                                }
                                newPath += splittedDataPath[i]
                            }
                        }
                        let dataPathEdited = newPath.slice(newPath.length -1, 1)
                        fullPath += newPath;
                    }
                }
                if(splittedPath.length === 5){
                    fullPath += '/1'
                }
            }

        }
        if(fullPath){
            navigate(fullPath)
        }
    }

    return (
            <Box display={{
                initial: 'none',
                sm: 'block'
            }}>
                <Accordion.Root className="AccordionRoot" type="multiple" collapsible>
                    <Accordion.Item className="AccordionItem" value="gender">
                        <AccordionTrigger><Heading weight={"medium"} size={"3"}>Пол</Heading></AccordionTrigger>
                        <AccordionContent>
                            {
                                categoryName !== 'hats' ?
                                    <Flex direction={"column"} gap={"4"}>
                                        <Flex align={"center"}>
                                            <Checkbox.Root checked={location.pathname.includes('gender=male')} onCheckedChange={(e) => handleChangeLink({
                                                type: 'gender',
                                                value: 'male',
                                                isOn: e
                                            })} className="CheckboxRoot" id="g1">
                                                <Checkbox.Indicator className="CheckboxIndicator">
                                                    <CheckIcon/>
                                                </Checkbox.Indicator>
                                            </Checkbox.Root>
                                            <label className="Label" htmlFor="g1">
                                                Мужской
                                            </label>
                                        </Flex>
                                        <Flex align={"center"}>
                                            <Checkbox.Root checked={location.pathname.includes('gender=female')} onCheckedChange={(e) => handleChangeLink({
                                                type: 'gender',
                                                value: 'female',
                                                isOn: e
                                            })} className="CheckboxRoot" id="g2">
                                                <Checkbox.Indicator className="CheckboxIndicator">
                                                    <CheckIcon/>
                                                </Checkbox.Indicator>
                                            </Checkbox.Root>
                                            <label className="Label" htmlFor="g2">
                                                Женский
                                            </label>
                                        </Flex>
                                    </Flex>
                                    : 'Любой'
                            }
                        </AccordionContent>
                    </Accordion.Item>

                    {subcats.length > 0 ? <Accordion.Item className="AccordionItem" value="category">
                        <AccordionTrigger><Heading weight={"medium"} size={"3"}>Категории</Heading></AccordionTrigger>
                        <AccordionContent>
                            <Flex direction={"column"} gap={"4"}>
                                {
                                    subcats ? subcats.map((item, index) => {
                                        return  (<Flex align={"center"}>
                                            <Checkbox.Root checked={location.pathname.includes('sub=' + item.name)} onCheckedChange={(e) => handleChangeLink({
                                                type: 'sub',
                                                value: item.name,
                                                isOn: e
                                            })} className="CheckboxRoot" id={"c" + index}>
                                                <Checkbox.Indicator className="CheckboxIndicator">
                                                    <CheckIcon/>
                                                </Checkbox.Indicator>
                                            </Checkbox.Root>
                                            <label className="Label" htmlFor={"c" + index}>
                                                {item.title}
                                            </label>
                                        </Flex>)

                                    }) : ''
                                }
                            </Flex>

                        </AccordionContent>
                    </Accordion.Item> : ''}
                </Accordion.Root>
                {/*<Button style={{*/}
                {/*    backgroundColor: 'black',*/}
                {/*    cursor: "pointer",*/}
                {/*    width: "300px",*/}
                {/*    marginTop: 20,*/}
                {/*}} size="4" variant="solid">*/}
                {/*    <Heading weight={"medium"} size={"3"}>Показать товары</Heading>*/}
                {/*</Button>*/}
            </Box>

    )

}


