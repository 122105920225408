import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
    HamburgerMenuIcon,
    DotFilledIcon,
    CheckIcon,
    ChevronRightIcon, BarChartIcon,
} from '@radix-ui/react-icons';
import './DropdownFilters.scss';
import {Box, Text, Button} from "@radix-ui/themes";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";

const DropdownFilters = () => {
    let { categoryName } = useParams()
    let location = useLocation()
    let navigate = useNavigate()
    let subcats = [];
    if(categoryName === 'bags'){
        subcats = [{
            name: 'tout',
            title: 'Тоут',

        },{
            name: 'hand',
            title: 'С ручками',

        },{
            name: 'clutch',
            title: 'Клатчи',

        },{
            name: 'briefcase',
            title: 'Портфели',

        },{
            name: 'travelling',
            title: 'Дорожные сумки',

        },{
            name: 'shoulder',
            title: 'Через плечо',

        },{
            name: 'backpack',
            title: 'Рюкзаки',
        },]
    }
    if(categoryName === 'accessories'){
        subcats = [{
            name: 'lighters',
            title: 'Зажигалки',

        },{
            name: 'scarf',
            title: 'Шарфы и платки',

        },{
            name: 'watches',
            title: 'Часы',

        },{
            name: 'belt',
            title: 'Ремни',

        },{
            name: 'gloves',
            title: 'Перчатки',

        },{
            name: 'cardCase',
            title: 'Кардхолдеры',

        },{
            name: 'keyPurse',
            title: 'Ключницы',
        },{
            name: 'changePurse',
            title: 'Мелочницы',
        },{
            name: 'wallet',
            title: 'Кошельки',
        },{
            name: 'sunglasses',
            title: 'Солнцезащитные очки',
        },{
            name: 'otherGoods',
            title: 'Прочее',
        },]
    }
    if(categoryName === 'jewelery'){
        subcats = [{
            name: 'walletChain',
            title: 'Цепи для кошелька',

        },{
            name: 'bracelet',
            title: 'Браслеты',

        },{
            name: 'earrings',
            title: 'Серьги',

        },{
            name: 'pendant',
            title: 'Подвески',

        },{
            name: 'ring',
            title: 'Кольца',

        },{
            name: 'necklace',
            title: 'Цепочки на шею',
        }]
    }
    const [bookmarksChecked, setBookmarksChecked] = React.useState(true);
    const [urlsChecked, setUrlsChecked] = React.useState(false);
    let handleChangeLink = (data) => {
        let fullPath = ''
        if(data.isOn){
            let splittedPath = location.pathname.split('/')
            if(splittedPath.length === 3){
                fullPath = '/' + splittedPath[1] + '/' + splittedPath[2] + '/' + data.type + '=' + data.value
            }
            if(splittedPath.length === 4){
                fullPath = '/' + splittedPath[1] + '/' + splittedPath[2]
                if(splittedPath[3].includes('gender') || splittedPath[3].includes('sub')){
                    fullPath += '/' + splittedPath[3] + '&' + data.type + '=' + data.value
                } else {
                    fullPath += '/' + data.type + '=' + data.value + '/1'
                }

            }
            if(splittedPath.length === 5){
                fullPath = '/' + splittedPath[1] + '/' + splittedPath[2] + '/' + splittedPath[3] + '&' + data.type + '=' + data.value + '/' + 1
            }
        } else{
            let splittedPath = location.pathname.split('/')
            if(splittedPath.length === 3){
                fullPath += '/' + data.type + '=' + data.value + '/1'
            }
            if(splittedPath.length === 4 || splittedPath.length === 5){
                fullPath = '/' + splittedPath[1] + '/' + splittedPath[2]
                if(splittedPath[3].includes('gender') || splittedPath[3].includes('sub')){
                    let splittedDataPath = splittedPath[3].split('&');
                    if(splittedDataPath.length > 1){
                        let newPath = '/';
                        let isFirst = true
                        for(let i = 0; i < splittedDataPath.length; i++){
                            if(data.type + '=' + data.value !== splittedDataPath[i]){
                                if(!isFirst){
                                    newPath += '&'
                                } else {
                                    isFirst = false
                                }
                                newPath += splittedDataPath[i]
                            }
                        }
                        let dataPathEdited = newPath.slice(newPath.length -1, 1)
                        fullPath += newPath;
                    }
                }
                if(splittedPath.length === 5){
                    fullPath += '/1'
                }
            }

        }
        if(fullPath){
            navigate(fullPath)
        }
    }
    return (
        <Box display={{
            initial: 'block',
            sm: 'none'
        }}>
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                    <Button size={{
                        initial: '1',
                        sm: '2'
                    }} mb={'4'} color={'gray'} style={{color: "black", boxShadow: 'inset 0 0 0 0.5px black', cursor: "pointer"}} variant={'outline'}>Фильтры</Button>

                </DropdownMenu.Trigger>

                <DropdownMenu.Portal>
                    <DropdownMenu.Content align={'end'} className="DropdownMenuContent" sideOffset={5}>
                        <DropdownMenu.Label className="DropdownMenuLabel">Пол</DropdownMenu.Label>
                        { categoryName !== 'hats' ? <>
                            <DropdownMenu.CheckboxItem
                                className="DropdownMenuCheckboxItem"
                                checked={location.pathname.includes('gender=male')} onCheckedChange={(e) => handleChangeLink({
                                type: 'gender',
                                value: 'male',
                                isOn: e
                            })}
                            >
                                <DropdownMenu.ItemIndicator className="DropdownMenuItemIndicator">
                                    <CheckIcon />
                                </DropdownMenu.ItemIndicator>
                                Мужской
                            </DropdownMenu.CheckboxItem>
                            <DropdownMenu.CheckboxItem
                                className="DropdownMenuCheckboxItem"
                                checked={location.pathname.includes('gender=female')} onCheckedChange={(e) => handleChangeLink({
                                type: 'gender',
                                value: 'female',
                                isOn: e
                            })}
                            >
                                <DropdownMenu.ItemIndicator className="DropdownMenuItemIndicator">
                                    <CheckIcon />
                                </DropdownMenu.ItemIndicator>
                                Женский
                            </DropdownMenu.CheckboxItem></> : <DropdownMenu.Label className="DropdownMenuLabel">Любой</DropdownMenu.Label>}

                        {subcats.length > 0 ? <DropdownMenu.Label className="DropdownMenuLabel">Категории</DropdownMenu.Label> : ''}
                        {subcats.length > 0 ? subcats.map((item, index) => {
                            return(
                                <DropdownMenu.CheckboxItem
                                    className="DropdownMenuCheckboxItem"
                                    checked={location.pathname.includes('sub=' + item.name)}
                                    onCheckedChange={(e) => handleChangeLink({
                                        type: 'sub',
                                        value: item.name,
                                        isOn: e
                                    })}
                                >
                                    <DropdownMenu.ItemIndicator className="DropdownMenuItemIndicator">
                                        <CheckIcon />
                                    </DropdownMenu.ItemIndicator>
                                    {item.title}
                                </DropdownMenu.CheckboxItem>
                            )
                        }) : ''}

                        <DropdownMenu.Arrow className="DropdownMenuArrow" />
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        </Box>

    );
};

export default DropdownFilters;