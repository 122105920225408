export let FavoritesAPI = {
    getCookie: (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },
    setCookie: (name,value,days) => {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    },
    addToFavorites: (id) => {
        let oldFavorite = FavoritesAPI.getCookie('favorites');
        console.log(oldFavorite)
        if(!oldFavorite){
            FavoritesAPI.setCookie('favorites', JSON.stringify([id]))
        } else {
            let data = JSON.parse(oldFavorite);
            if(data.indexOf(id) > -1){
                return
            }
            data.push(id);
            console.log(data)
            FavoritesAPI.setCookie('favorites', JSON.stringify(data))
        }
    },
    removeFromFavorites: (id) => {
        let oldFavorite = FavoritesAPI.getCookie('favorites');

        if(!oldFavorite){
            return
        }
        let data = JSON.parse(oldFavorite);
        let index = data.indexOf(id);
        if(index > -1){
            data.splice(index, 1);
            FavoritesAPI.setCookie('favorites', JSON.stringify(data));
        }
    },
    getFavorites: () => {
        let oldFavorite = FavoritesAPI.getCookie('favorites');
        if(!oldFavorite){
            return
        }
        return JSON.parse(oldFavorite)
    }
}