import {Box, Container, Flex, Heading} from "@radix-ui/themes";
import {Link, useLocation, useParams} from 'react-router-dom'
import CatalogGrid from "./CatalogGrid/CatalogGrid";
import {useEffect, useState} from "react";
import DropdownFilters from "./DropdownFilters/DropdownFilters";


export default function Catalog(props) {
    let { categoryName } = useParams();
    let location = useLocation();
    let { dataPath } = useParams();
    let { page } = useParams();
    const [items, setItems] = useState([])
    const [pagesTotal, setPagesTotal] = useState(0)
    const [goodsTotal, setGoodsTotal] = useState(0)
    const [isNotFound, setIsNotFound] = useState(false)
    let fetchData = async () => {
        if(!props.isHome){
            try {
                let splittedLocation = location.pathname.split('/');

                let reqPath = ''
                if(splittedLocation.length === 4 || splittedLocation.length === 3){
                    reqPath += splittedLocation[2]
                }
                if(splittedLocation.length === 5){
                    reqPath += splittedLocation[2] + '/' + splittedLocation[3]
                }


                let data = await fetch('https://api.basetwentyfour.com:6500/category/' + reqPath)

                let parsedData = await data.json()
                if(parsedData.status === 200){
                    setPagesTotal(Math.ceil(parsedData.data.length / 48))
                    setGoodsTotal(parsedData.data.length)

                    if(page){
                        if(page === "1"){
                            setItems(parsedData.data.slice(0, 48 ))
                        } else {
                            setItems(parsedData.data.splice(page * 47, 48))
                        }
                    } else {
                        setItems(parsedData.data.slice(0, 48))
                    }
                }
                console.log(parsedData)
                if(parsedData.status === 404){
                    setIsNotFound(true)
                }
            } catch (e){
                console.log(e)
            }
        } else {
            let data = await fetch('https://api.basetwentyfour.com:6500/all')

            let parsedData = await data.json()
            if(parsedData.status === 200){
                let items = []
                for(let page of parsedData.data.items){
                    for(let item of page){
                        items.push(item)
                    }
                }
                setPagesTotal(Math.ceil(items.length / 48))
                setGoodsTotal(items.length)

                if(page){
                    if(page === "1"){
                        setItems(items.slice(0, 48 ))
                    } else {
                        setItems(items.splice(page * 47, 48))
                    }
                } else {
                    setItems(items.slice(0, 48))
                }
            }
            console.log(parsedData)
            if(parsedData.status === 404){
                setIsNotFound(true)
            }
        }

    }
    useEffect( () => {
        setItems([])
        setGoodsTotal(0)
        setIsNotFound(false)
        fetchData()
    }, [location]);
    let categoryTitle = 'Все товары'
    if(categoryName === 'hats'){
        categoryTitle = 'Шапки'
    }
    if(categoryName === 'bags'){
        categoryTitle = 'Сумки'
    }
    if(categoryName === 'accessories'){
        categoryTitle = 'Аксессуары'
    }
    if(categoryName === 'jewelery'){
        categoryTitle = 'Ювелирные изделия'
    }

    return (
        <Box>
            <Container pt={{
                initial: '5',
                sm: '9'
            }} size={{
                lg: '4',
                md: '3',
                sm: '2',
                xs: '1'
            }} px={{
                initial: '4',
                sm: '1'
            }}>

                <Flex direction={"row"} justify={!props.isHome ?{
                    initial: 'between',
                    md: 'center'
                } : {
                    initial: 'center',
                }} align={"center"}>
                    <Heading mb="1" weight={"bold"} size="7">{categoryTitle}</Heading>
                </Flex>
                <Flex justify={!props.isHome ? 'start' : 'center'} align={"end"} gap={"1"}>
                    <Heading mb="2" weight={"light"} size="3">Товаров:</Heading>
                    <Heading mb="2" weight={"medium"} size="4">{goodsTotal}</Heading>
                </Flex>
                {!props.isHome ? <DropdownFilters/>: ''}

            </Container>
            <CatalogGrid isHome={props.isHome} isNotFound={isNotFound} pagesTotal={pagesTotal} items={items} page={page}/>
        </Box>
    )
}