import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
    HamburgerMenuIcon,
    DotFilledIcon,
    CheckIcon,
    ChevronRightIcon,
} from '@radix-ui/react-icons';
import './Dropdown.scss';
import {Button} from "@radix-ui/themes";
import {Link} from "react-router-dom";

const Dropdown = () => {
    const [bookmarksChecked, setBookmarksChecked] = React.useState(true);
    const [urlsChecked, setUrlsChecked] = React.useState(false);
    const [person, setPerson] = React.useState('pedro');

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <Button size={{
                    initial: '1',
                    sm: '2'
                }} color={'gray'} style={{color: "black", boxShadow: 'inset 0 0 0 0.5px rgba(0,0,0,0.3)', cursor: "pointer"}} variant={'outline'}><HamburgerMenuIcon width={"20"} height={"20"} color={'black'}/> Категории</Button>

            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content align={'start'} className="DropdownMenuContent" sideOffset={5}>
                    <Link to={'/'}>
                        <DropdownMenu.Item className="DropdownMenuItem">
                            Главная
                        </DropdownMenu.Item>
                    </Link>
                    <Link to={'/category/hats/1'}>
                        <DropdownMenu.Item className="DropdownMenuItem">
                            Шапки
                        </DropdownMenu.Item>
                    </Link>
                    <Link to={'/category/bags/1'}>
                        <DropdownMenu.Item className="DropdownMenuItem">
                            Сумки
                        </DropdownMenu.Item>
                    </Link>
                    <Link to={'/category/accessories/1'}>
                    <DropdownMenu.Item className="DropdownMenuItem">
                        Аксессуары
                    </DropdownMenu.Item>
                    </Link>
                    <Link to={'/category/jewelery/1'}>
                        <DropdownMenu.Item className="DropdownMenuItem">
                            Ювелирные изделия
                        </DropdownMenu.Item>
                    </Link>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};

export default Dropdown;