import './App.css';
import '@radix-ui/themes/styles.css';
import {BrowserRouter, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import CatalogPage from "./pages/CatalogPage";
import ItemPage from "./pages/ItemPage";
import Favorites from "./components/Catalog/Favorites/Favorites";
import FavoritesPage from "./pages/FavoritesPage";
import {useEffect} from "react";

function App() {
  return (

          <Routes>
              <Route path="/" element={<CatalogPage isHome={true} />} />
              <Route path="/:page" element={<CatalogPage isHome={true} />} />
              <Route path="/products/:itemId" element={<ItemPage />} />
              <Route path="/category/:categoryName/:dataPath/:page" element={<CatalogPage />} />
              <Route path="/category/:categoryName/:page" element={<CatalogPage />} />
              <Route path="/category/:categoryName" element={<CatalogPage />} />
              <Route path="/favorites/:page" element={<FavoritesPage />} />
          </Routes>
  );
}

export default App;
