import {Theme} from "@radix-ui/themes";
import Header from "../components/Header/Header";
import Catalog from "../components/Catalog/Catalog";
import Footer from "../components/Footer/Footer";

export default function CatalogPage(props){
    return (
        <Theme>
            <Header/>
            <Catalog isHome={props.isHome}/>
            <Footer/>
        </Theme>

    )
}