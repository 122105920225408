import {Box, Button, Flex, IconButton, Text, TextField} from "@radix-ui/themes";
import {
    BarChartIcon,
    DotsHorizontalIcon,
    HamburgerMenuIcon,
    MagnifyingGlassIcon,
    StarFilledIcon
} from "@radix-ui/react-icons";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {FavoritesAPI} from "../../../../lib/helpers/FavoritesAPI";
import {grayColors} from "@radix-ui/themes/props";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Dropdown from "../Dropdown/Dropdown";

export default function NavBar() {
    const [favorites, setFavorites] = useState([])
    useEffect(() => {
        let fav = FavoritesAPI.getFavorites()
        setFavorites(fav);
    }, []);
    return (
        <Box mt={"4"}>
            <Flex align={"center"} justify={"between"}>
                <Box display={{
                    initial: 'block',
                    md: 'none'
                }}>
                    <Dropdown/>
                </Box>
                <Flex display={{
                    initial: 'none',
                    md: 'flex'
                }} gap={"6"}>
                    <Link to={'/'} style={{
                        color: 'black',
                        textDecoration: "none"
                    }}>
                        <Text size={"3"}>Главная</Text>
                    </Link>
                    <Link to={'/category/hats/1'} style={{
                        color: 'black',
                        textDecoration: "none"
                    }}>
                        <Text size={"3"}>Шапки</Text>
                    </Link>
                    <Link to={'/category/bags/1'} style={{
                        color: 'black',
                        textDecoration: "none"
                    }}>
                        <Text size={"3"}>Сумки</Text>
                    </Link>
                    <Link to={'/category/accessories/1'} style={{
                        color: 'black',
                        textDecoration: "none"
                    }}>
                        <Text size={"3"}>Аксессуары</Text>
                    </Link>
                    <Link to={'/category/jewelery/1'} style={{
                        color: 'black',
                        textDecoration: "none"
                    }}>
                        <Text size={"3"}>Ювелирные изделия</Text>
                    </Link>
                </Flex>
                <Flex gap={'2'}>
                    <Link to={'/favorites/1'}>
                        <Button size={{
                            initial: '1',
                            xs: '2'
                        }} color={'gray'} style={{color: "black", boxShadow: 'inset 0 0 0 0.5px rgba(0,0,0,0.3)', cursor: "pointer"}} variant={'outline'}><StarFilledIcon width={"20"} height={"20"} color={'black'}/></Button>
                    </Link>
                    <Link to={'https://t.me/basetwentyfour'}>
                        <Button size={{
                            initial: '1',
                            xs: '2'
                        }} color={'gray'} style={{color: "black", boxShadow: 'inset 0 0 0 0.5px rgba(0,0,0,0.3)', cursor: "pointer"}} variant={'outline'}>Связаться с нами</Button>
                    </Link>
                </Flex>
            </Flex>
        </Box>
    )
}